export default {
  save: "Save",
  saveChanges: "Save Changes",
  undoChanges: "Undo Changes",
  create: "Create",
  add: "Add",
  new: "New",
  assign: "Assign",
  change: "Change",
  next: "Next",
  back: "Back",
  edit: "Edit",
  continue: "Continue",
  confirm: "Confirm",
  remove: "Remove",
  submit: "Submit",
  submitted: "Submitted",
  transfer: "Transfer",
  send: "Send",
  yes: "Yes",
  no: "No",
  models: "Models",
  cancel: "Cancel",
  OK: "OK",
  tryAgain: "Try again",
  createNewAttribute: "Create new attribute",
  addExistingAttribute: "Add existing attribute",
  addNewProject: "Add new project",
  addNewBillingAddress: "Add new billing address",
  done: "Done",
  SearchByFilters: "Search by filters",
  enterCoordinates: "Enter coordinates",
  reserve: "Reserve",
  settings: "Settings",
  requestDataCorrection: "Request data correction",
  approve: "Approve",
  deny: "Deny",
  delete: "Delete",
  run: "Run",
  unselect: "Unselect",
  attributeSets: "Attribute Sets",
  attributes: "Attributes",
  createdBy: "created by",
  sort: "sort",
  replace: "replace",
  unassign: "unassign",
  photo: "Photos",
  newLog: "New Log",
  or: "or",
  downloadMap: "Download map as JPG",
  showRelatedAssets: "Show related assets",
  hideRelatedAssets: "hide related assets",
  showAllAssets: "Show all Assets",
  resetAllFilters: "Reset all filters",
  hideAllAssets: "Hide all Assets",
  hideAll: "Hide all",
  showAll: "Show all",
  copyAccessRoute: "Copy access route",
  check: "Check",
  goToMoreDetails: "Show more details",
  clickToUpload: "click to upload",
  here: "here",
  orDragandDrop: "drag and drop",
  uploadPicture: "Upload Picture",
  uploadFiles: "Upload Files",
  deletePictures: "Delete Pictures",
  deleteFiles: "Delete Files",
  stopDeleting: "Stop deleting",
  logout: "Logout",
  editName: "Edit Name",
  changePassword: "Change Password",
  editData: "Edit Data",
};
