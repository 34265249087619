/* eslint-disable react/jsx-max-depth */
import React, { createContext } from "react";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, styled, StyledEngineProvider } from "@mui/material";
import client from "apollo/client";
import { ClickToComponent } from "click-to-react-component";
import { RecoilRoot } from "recoil";
import "./App.css";
//import theme from "themes/theme";
import StartupOperations from "components/organisms/StartupOperations";
import MetaDecorator from "components/templates/MetaDecorator";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RecoilDevTools, RecoilTunnel } from "recoil-toolkit";
import { useCustomThemeManager } from "themes/useCustomTheme";

import { LicenseInfo } from "@mui/x-license-pro";
import AuthenticationCompontent from "authentication/AuthenticationCompontent";
import { Router } from "routes/utils/Router";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_KEY ?? "");
export const ColorModeContext = createContext({ toggleColorMode: () => {} });
const AppInner = () => {
  const { colorMode, theme } = useCustomThemeManager();
  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* <ErrorBoundary> */}
            <AuthenticationCompontent>
              <StartupOperations>
                <CssBaseline />
                <MetaDecorator />
                {/* <ClickToComponent />x */}
                <Router />
                <RecoilTunnel />
                <RecoilDevTools
                  forceSerialize
                  serializer={(obj: unknown) => {
                    return JSON.stringify(obj, (key, value) => {
                      if (Object.hasOwn(value ?? {}, "$$typeof")) return "React.element";
                      return value;
                    });
                  }}
                />
              </StartupOperations>
            </AuthenticationCompontent>
            {/* </ErrorBoundary> */}
          </LocalizationProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};

function App() {
  return (
    // <AuthenticationCompontent>
    //   <AuthorizationComponent>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <AppInner />
      </ApolloProvider>
    </RecoilRoot>
    //   </AuthorizationComponent>
    // </AuthenticationCompontent>
  );
}

export default App;
