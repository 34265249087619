export default {
  label: "Rechnungsadresse",
  name: "Name/Firma",
  address: "Adresse",
  PONumber: "PO Nummer",
  note: "Anmerkung",
  requiredField: "Dieses Feld ist erforderlich.",
  postalCodeErrorMessage: "Bitte geben Sie eine gültige Postleitzahl ein, die nur aus Ziffern besteht.",
  deleteBillingAddress: "Rechnungsadresse löschen",
  deleteBillingAddressMessage: "Möchten Sie diese Rechnungsadresse wirklich löschen?",
  deleteBillingAddressesNotPossible:
    "Das Löschen der Rechnungsadresse ist nicht möglich, da eine der Adressen mit mindestens einer Anlage verknüpft ist.",
  deleteBillingAddressNotPossible:
    "Es ist nicht möglich, die Rechnungsadresse zu löschen, da die Rechnung mit mindestens einer Anlage verknüpft ist.",
};
