export default {
  label: "Anlagenübersicht",
  allAssets: "Alle Anlagen",
  tabs: {
    assets: {
      deleteAsset: "Anlage löschen",
      pendingAssets: "Ausstehende Anlagen",
      deleteAssetMessagePart1: "Sie löschen die Anlage",
      deleteAssetMessagePart2: "aus der Datenbank. Sind Sie sicher, dass Sie fortfahren möchten?",
      deleteAssetMessagePluralPart1: "Sie löschen die Anlagen",
      allAssetView: "Ansicht aller Anlagen",
      projectView: "Projektansicht",
      label: "Anlagen",
      labelForProjectAndAssetView: "Anlagen & Projekte",
      asset: "Anlage",
      generalInfos: "Allgemeine Informationen",
      note: "Bemerkung",
    },
    importAssets: {
      importSuccessTitle: "Anlagen erfolgreich importiert",
      importSuccessMessage:
        "Alle Anlagen wurden erfolgreich hochgeladen. Um den Import abzuschließen,wechseln Sie bitte in den Reiter „Ausstehende Anlagen“ und vervollständigen Sie die Dateneingabe.",
      importNotPossibleTitle: "Anlagen konnten nicht importiert werden",
      importNotPossibleMessagePart1:
        "Der Import von Anlagen ist fehlgeschlagen. Es wurden Fehler in mindestens einer Anlage festgestellt: ",
      importNotPossibleMessagePart2:
        "Bitte überprüfen Sie die hochgeladene Datei noch einmal und versuchen Sie es erneut. Häufige Probleme sind:",
      importNotPossibleMessagePart3: "Doppelte Anlagen (zwei oder mehr Anlagen mit der gleichen Seriennummer)",
      importNotPossibleMessagePart4: "Anlage existiert bereits",
      importNotPossibleMessagePart5: "Falsches Format der erforderlichen Daten",
      importNotPossibleMessagePart6: "Fehlende erforderliche Daten",
    },
    assetGroups: {
      label: "Anlagegruppen",
      list: "Anlagenliste",
      activities: "Aktivitäten",
      newAssetGroup: "Neue Anlagegruppe",
      sort: {
        labelAsc: "ASC",
        labelDesc: "DESC",
      },
    },
    assetTags: {
      updateTags: "Tags aktualisieren",
    },
    assetTypes: {
      deleteAssetType: "Anlagentypen löschen",
      label: "Anlagentypen",
      newAssetType: "Neuer Anlagentyp",

      deleteAssetTypeMessagePart1: "Dieser AnlagenTyp kann nicht gelöscht werden, da er mit ",
      deleteAssetTypeMessagePart2:
        "Modell(e) Instanzen. Damit ein AnlagenTyp gelöscht werden kann, da er mit Modellen verknüpft ist",
      deleteAssetTypeMessage: "Sind Sie sicher, dass Sie diesen Anlagentyp löschen möchten?",
      resetToDefaultValue: "Zurücksetzen auf Standardwert",
      deleteAssetModelErrorMessage:
        "Bitte achten Sie darauf, nur Anlagenmodell zu löschen, denen keine Anlagen zugewiesen sind!",
      deleteAssetModelSuccessMessage: "Sie haben diese Anlagenmodelle erfolgreich gelöscht!",
      deleteAssetModelWarningMessage:
        "Bitte achten Sie darauf, nur Anlagenmodell zu löschen, denen keine Anlagen zugewiesen sind! Folgende Anlagen sind diesem Anlagemnodell zugewiesen.",
      deleteImage: {
        modalTitle: "Bild löschen",
        modalMessage: "Möchten Sie dieses Bild wirklich löschen?",
      },
    },
    assetModels: {
      deleteAssetModel: "Anlagenmodell löschen",
      deleteAssetModelMessage: "Sind Sie sicher, dass Sie dieses Anlagenmodell löschen möchten?",
      deleteAssetModelTitle: "Anlagenmodell löschen",

      deleteNotPossibleMessagePart1: "Dieses Anlagenmodell kann nicht gelöscht werden, da ihm ",
      deleteNotPossibleMessagePart2:
        "Anlage(n) zugeordnet sind. Damit ein Anlagenmodell gelöscht werden kann, dürfen keine Anlagen existieren.",
      deleteNotPossibleBecauseTheseModelsHaveAssets1:
        "Die ausgewählten Anlagenmodelle können nicht gelöscht werden, da nachfolgenden Modellen Anlagen zugewiesen sind:",
      deleteNotPossibleBecauseTheseModelsHaveAssets2:
        "Damit Anlagenmodelle gelöscht werden können, dürfen keine Anlagen zugeordnet sein.",
    },
    assetDetails: {
      label: "Anlagendetails",
      assetDetailsPageTabs: {
        position: "Position",
        addAnotherModel: "Ein weiteres Modell hinzufügen",
        attributes: "Attribute",
        photos: "Fotos",
        otherDocuments: "Andere Dokumente",
        assetPictures: "Anlagenbilder",
        mediaAndFiles: "Medien und Dateien",
        fileName: "Dateiname",
        fileSize: "Dateigröße [MB]",
        selectFile: "Datei auswählen",
        uploadedOn: "Hochgeladen am",
        comments: "Kommentare",
        changes: "Änderungen",
        deleteFilesMessage: "Möchten Sie die ausgewählten Dateien wirklich löschen?",
        deleteFileMessage: "Möchten Sie die ausgewählte Datei wirklich löschen?",
      },
    },
    assetAttributeFieldsAndSets: {
      label: "Attributfelder und -Sätze",
      deleteMessage:
        "Das Löschen dieses Satzes wirkt sich auf alle Anlagentypen aus, die ihn zur Anzeige der Attributwerte ihrer Instanzen ausgewählt haben. Sind Sie sicher, dass Sie es löschen wollen?",
      deleteAssetAttributeSets: "Attributsatz löschen",
      attributes: "Attribute",
      attribute: "Attribut",
      noAttributes: "Keine Attribute",
    },
    AssetAttributeSets: {
      label: "Attributsätze",
    },
    AssetAttributes: {
      createAssetAttribute: {
        successMessage: "Asset Attribut erfolgreich erstellt",
        errorMessage: "Fehler beim Erstellen des Asset Attributs",
      },
      editAssetAttribute: {
        successMessage: "Asset Attribut erfolgreich bearbeitet",
        errorMessage: "Fehler beim Bearbeiten des Asset Attributs",
      },
    },
    assetNewEntry: {
      label: "neue Einträge",
      newAssets: "Neue Anlagen",
      dateTime: "Datum & Uhrzeit",
    },
    projects: {
      label: "Projekte",
    },
    errorCodes: {
      noError: "Kein Fehler",
      fieldValidationError:
        "Einige Felder enthalten ungültige Daten. Bitte überprüfen Sie die nachfolgenden Felder und versuchen Sie es erneut.",
      csvParsingError:
        "Die Datei konnte nicht korrekt gelesen werden. Stellen Sie sicher, dass die Datei im richtigen Format vorliegt und keine beschädigten Daten enthält.",
      assetAlreadyExistsError:
        "Ein Eintrag mit derselben Anlage existiert bereits. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.",
      manufacturerMissing:
        "Herstellerangaben fehlen. Bitte füllen Sie alle erforderlichen Felder aus und versuchen Sie es erneut.",
      modelMissing:
        "Modellangaben fehlen. Bitte füllen Sie alle erforderlichen Felder aus und versuchen Sie es erneut.",
      unknownError:
        "Ein unbekannter Fehler ist aufgetreten. Bitte prüfen Sie ihre Eingaben oder wenden Sie sich an den Support.",
    },
    safetyNotes: {
      label: "Produktsicherheitsmitteilungen",
      newSafetyNote: "Neuer Produktsicherheitsmitteilung",
      updateSafetyNote: "Produktsicherheitsmitteilung bearbeiten",
      safetyNoteDetails: "Produktsicherheitsmitteilungsdetails",
      deleteSafetyNoteMessage: "Sind Sie sicher, dass Sie diesen Produktsicherheitsmitteilung löschen wollen?",
      deleteSafetyNote: "Produktsicherheitsmitteilung löschen",
    },
  },
};
