export default {
  addApp: "Add app",
  addButton: "Add Assets",
  addBillingAddress: "Add Billing Address",
  addModelButton: "add Model",
  columnOptions: "Column Options",
  delete: "delete",
  density: "density",
  compact: "compact",
  standard: "standard",
  comfortable: "comfortable",
  edit: "edit",
  filter: "filter",
  checkboxSelection: "Checkbox Selection",
  importAssets: "Import Assets",
  exportAllContacts: "Export all contacts",
  exportAllAssets: "Export all Assets",
  removeApp: "Remove app",
  rowsPerPage: "Rows per page",
  search: "Search",
  filterValue: "Filter value",
  searchTable: "Search table",
  serialNo: "serialNo",
  findColumn: "Find column",
  sort: "sort",
  tags: "Tags",
  gotIt: "Got it",
  deleteNotPossible: "Delete not possible",
  editUser: "Edit User",
  blockUser: "Block User",
  editTenant: "Edit Tenant",
  noData: "No Data Available",
};
