export default {
  label: "Asset Management",
  allAssets: "All Assets",
  tabs: {
    assets: {
      deleteAsset: "Delete Asset",
      pendingAssets: "Pending Assets",
      deleteAssetMessagePart1: "You are deleting the Asset",
      deleteAssetMessagePart2: "from the database. Are you sure you want to proceed?",
      deleteAssetMessagePluralPart1: "You are deleting the Assets",
      allAssetView: "View all Assets",
      projectView: "Project View",
      label: "Asset List",
      asset: "Asset",
      labelForProjectAndAssetView: "Assets & Projects",
      generalInfos: "General Information",
      note: "Note",
    },
    importAssets: {
      importSuccessTitle: "Assets imported successfully",
      importSuccessMessage:
        "All 3 assets were uploaded successfully. To complete the import, please go to the 'Pending Assets' section where you can finalize the process by assigning routes, uploading photos, and verifying the scanned data for accuracy.",
      importNotPossibleTitle: "Assets could not be imported",
      importNotPossibleMessagePart1: "The import of assets failed. There were issues found in the following cells: ",
      importNotPossibleMessagePart2: "Please review the uploaded file again and try again. Common issues are:",
      importNotPossibleMessagePart3: "Duplicate assets (two or more assets with the same serial number)",
      importNotPossibleMessagePart4: "Asset already exists",
      importNotPossibleMessagePart5: "Incorrect format of required data",
      importNotPossibleMessagePart6: "Missing required data",
    },
    assetGroups: {
      label: "Asset Groups",
      list: "Asset List",
      activities: "Activities",
      newAssetGroup: "New Asset Group",
      sort: {
        labelAsc: "ASC",
        labelDesc: "DESC",
      },
    },
    assetTags: {
      updateTags: "Update Tags",
    },
    assetTypes: {
      label: "Asset Types",
      deleteAssetType: "Delete Asset Type",
      deleteAssetTypeMessage: "Are you sure you want to delete this asset type?",
      newAssetType: "New Asset Type",
      resetToDefaultValue: "Reset to default value",

      deleteAssetTypeMessagePart1: "This asset type cannot be deleted since it's assiciated with ",
      deleteAssetTypeMessagePart2: "Model(s) instances. For an asset type to be deleted, no asset Models may exist.",
      deleteAssetModelErrorMessage: "Please make sure that you only delete Models that have no Assets assigned!",
      deleteAssetModelSuccessMessage: "You have successfully deleted the AssetModel(s)",
      deleteAssetModelWarningMessage:
        " You can not delete AssetModels if they have Asset(s) assigned to them. Asset(s) that are assigned to the AssetModel(s)",
      deleteImage: {
        modalTitle: "Delete Image",
        modalMessage: "Are you sure you want to delete this image?",
      },
    },
    assetModels: {
      deleteAssetModel: "Delete Asset Model",
      deleteAssetModelMessage: "Are you sure you want to delete this Asset Model(s)?",
      deleteAssetModelTitle: "Delete Asset Model",
      deleteNotPossibleMessagePart1: "This asset model cannot be deleted since it's associated with ",
      deleteNotPossibleMessagePart2: "Model(s) instances. For an asset model to be deleted, no assets may exist.",
      deleteNotPossibleBecauseTheseModelsHaveAssets1:
        "Selected asset models can not be deleted because the following models have assets assigned to them:",
      deleteNotPossibleBecauseTheseModelsHaveAssets2: "In order to delete asset models, no assets must be assigned.",
    },
    assetDetails: {
      label: "Asset details",
      assetDetailsPageTabs: {
        position: "Position",
        attributes: "Attributes",

        addAnotherModel: "Add Another Model",
        photos: "Photos",
        assetPictures: "Asset Pictures",
        mediaAndFiles: "Media & Files",
        fileName: "File Name",
        selectFile: "Select File",
        fileSize: "File Size",
        uploadedOn: "Uploaded on [MB]",
        otherDocuments: "Other Documents",
        comments: "Comments",
        changes: "Changes",
        deleteFilesMessage: "Are you sure you want to delete the selected attachments?",
        deleteFileMessage: "Are you sure you want to delete this attachment?",
      },
    },
    assetAttributeFieldsAndSets: {
      label: "Attribute Fields & Sets",
      deleteMessage:
        "Deleting this set will affect all asset types that have selected it for displaying attribute values of their instances. Are you sure you want to delete it?",
      deleteAssetAttributeSets: "delete Asset Attribute Set",
      attributes: "Attributes",
      attribute: "Attribute",
      noAttributes: "No Attributes",
    },
    AssetAttributes: {
      createAssetAttribute: {
        successMessage: "Asset Attribute created successfully",
        errorMessage: "Error creating Asset Attribute",
      },
      editAssetAttribute: {
        successMessage: "Asset Attribute edited successfully",
        errorMessage: "Error editing Asset Attribute",
      },
    },
    AssetAttributeSets: {
      label: "Attribute Sets",
    },
    assetNewEntry: {
      label: "New Entries",
      newAssets: "New Assets",
      dateTime: "Date & Time",
    },
    projects: {
      label: "Projects",
    },
    errorCodes: {
      noError: "No error occurred",

      fieldValidationError: "Some fields contain invalid data. Please check the following fields and try again.",
      csvParsingError:
        "The file could not be read correctly. Please make sure the file is in the correct format and does not contain any corrupted data.",
      assetAlreadyExistsError:
        "An asset with the same serial number already exists in the system. Please make sure the serial number is unique and try again.",
      manufacturerMissing: "Manufacturer information is missing. Please fill in all required fields and try again.",
      modelMissing: "Model information is missing. Please fill in all required fields and try again.",
      unknownError:
        "An unknown error occurred. Please try again or contact the system administrator if the problem persists.",
    },
    safetyNotes: {
      label: "Safety Notes",
      safetyNoteDetails: "Safety Note Details",
      newSafetyNote: "New Safety Note",
      updateSafetyNote: "Update Safety Note",
      deleteSafetyNoteMessage: "Are you sure you want to delete this safety note?",
      deleteSafetyNote: "Delete Safety Note",
    },
  },
};
