/* eslint-disable import/no-unresolved */
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Box, Tooltip } from "@mui/material";
import LoadingSpinner from "components/atomics/Loaders/LoadingSpinner";
import AssetManagementIcon from "components/icons/iconComponents/AssetManagementIcon";
import MapIcon from "components/icons/iconComponents/MapIcon";
import SettingsIcon from "components/icons/iconComponents/SettingsIcon";
import UserAuthIcon from "components/icons/iconComponents/UserAuthIcon";
import MainLayout from "components/templates/MainLayout";
import Configurator from "raft/configurator";
import { ComponentType, lazy, Suspense, SVGProps } from "react";
import projectColors from "themes/projectColors";
import translations from "translations";
import { Route } from "./utils/types";
import { ScopeEntry } from "authorization/types";
import { hasScope } from "authorization";
import ErrorElement from "components/templates/MainLayout/ErrorElement";

//const Home = () => import("pages/Home");
const AssetManagement = () => import("pages/AssetManagement");
const Assets = () => import("pages/AssetManagement/Assets");
const AssetDetails = () => import("pages/AssetManagement/Assets/AssetOverview/AssetDetails");
const AssetTypes = () => import("pages/AssetManagement/AssetTypes");
const AssetPositioning = () => import("pages/AssetPositioning");
const AssetCreateForm = () => import("pages/AssetManagement/Assets/AssetOverview/AddAssetForm");
const AssetUpdateForm = () => import("pages/AssetManagement/Assets/AssetOverview/EditAssetForm");
const AssetTypeCreate = () => import("pages/AssetManagement/AssetTypes/AssetTypeMutations/AssetTypeCreate");
const AssetTypeUpdate = () => import("pages/AssetManagement/AssetTypes/AssetTypeMutations/AssetTypeUpdate");
const AssetAttributesSets = () => import("pages/AssetManagement/AttributeSetsAndfields");
const UserManagement = () => import("pages/UserManagement");
const MappingRolesUserUpdate = () => import("pages/UserManagement/MappingRolesUserUpdate");
const UserRolesManager = () => import("pages/UserManagement/UserRolesManager");
const UpdateRole = () => import("pages/UserManagement/UpdateRole");
const PendingUserRequests = () => import("pages/UserManagement/PendingUserRequests");
const Settings = () => import("pages/Settings");
const Contact = () => import("pages/Settings/Contact");
const SafetyNotes = () => import("pages/AssetManagement/SafetyNotes");
const SafetyNoteCreate = () => import("pages/AssetManagement/SafetyNotes/SafetyNoteMutation/SafetyNoteCreate");
const SafetyNoteUpdate = () => import("pages/AssetManagement/SafetyNotes/SafetyNoteMutation/SafetyNoteUpdate");
const UserProfile = () => import("pages/UserProfile");
const PendingAssets = () => import("pages/AssetManagement/PendingAssets");
const NewEntry = () => import("pages/AssetManagement/NewEntry");
const EditNewEntry = () => import("pages/AssetManagement/NewEntry/EditNewEntry");
const NewEntryDetails = () => import("pages/AssetManagement/NewEntry/NewEntryDetails");
const AssetModelUpdate = () =>
  import("pages/AssetManagement/AssetTypes/AssetModels/AssetModelMutations/AssetModelUpdate");
const AssetModelCreate = () =>
  import("pages/AssetManagement/AssetTypes/AssetModels/AssetModelMutations/AssetModelCreate");

//TODO #6321 FM @next be version

const tooltip = (props: {
  icon: ({ isActive }: MenuIconProps & JSX.IntrinsicAttributes) => JSX.Element;
  isActive: boolean;
  title: string;
}) => (
  <Tooltip
    title={props.title}
    placement="right"
    componentsProps={{ tooltip: { sx: { backgroundColor: projectColors.functional.darkGray, maxHeight: "30px" } } }}
  >
    <div style={{ display: "flex" }}>{props.icon({ ...props })}</div>
  </Tooltip>
);

export type MenuIconProps = { isActive: boolean } & SVGProps<SVGSVGElement>;

const AsyncRoute = (ElementPre: () => Promise<{ default: ComponentType }>) => {
  const waiter = (time: number, fail = false) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        if (fail) {
          reject(false);
        } else {
          resolve(true);
        }
      }, time);
    });
  const Element = async () => {
    await waiter(50);
    const El = await ElementPre();
    return El;
  };

  const Page = lazy(Element);
  return (
    <Box>
      <Suspense fallback={<LoadingSpinner loading />}>
        <Page />
      </Suspense>
    </Box>
  );
};

const routes = (isAuthenticated: boolean): Route[] => {
  const billingAdressScope = hasScope("BillingAddress.Create.Tenant" || "BillingAddress.Create.All");
  const routesArray: Route[] = [
    {
      path: "",
      element: <MainLayout />,
      label: "",
      menuItem: false,
      mainLayout: false,
      scope: "Home",
      notification: true,
      errorElement: <ErrorElement />,
      children: [
        {
          path: "/",
          element: AsyncRoute(AssetManagement),
          label: translations.pages.home.label,
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },

        {
          path: "/assetManagement",
          element: AsyncRoute(AssetManagement),
          label: translations.pages.assetManagement.label,
          menuItem: true,
          mainLayout: true,
          menuIcon: ({ isActive }: { isActive: boolean }) =>
            tooltip({ icon: AssetManagementIcon, isActive, title: translations.pages.assetManagement.label }),
          scope: "Home",

          notification: false,
          children: [
            {
              path: "assets",
              element: AsyncRoute(Assets),
              label: translations.pages.assetManagement.tabs.assets.label,
              menuItem: true,
              mainLayout: true,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Home",

              notification: false,
              children: [
                {
                  path: ":id",
                  element: AsyncRoute(AssetDetails),
                  menuItem: false,
                  label: translations.pages.assetManagement.tabs.assets.label,
                  notification: false,
                  mainLayout: true,
                  scope: "Home",
                },
              ],
            },
            {
              path: "newAssets",
              element: AsyncRoute(AssetCreateForm),
              label: translations.pages.assetManagement.tabs.assets.label,
              menuItem: true,
              mainLayout: true,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Asset.Update",
              // scope needs to be changed to asset.create once provided by backend
              notification: false,
              children: [],
            },

            {
              path: "updateAsset/:id",
              element: AsyncRoute(AssetUpdateForm),
              label: translations.pages.assetManagement.tabs.assets.label,
              menuItem: false,
              mainLayout: true,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Asset.Update",
              notification: false,
              children: [],
            },
            {
              path: "updateNewEntry/:id",
              element: AsyncRoute(EditNewEntry),
              label: translations.pages.assetManagement.tabs.assets.label,
              menuItem: false,
              mainLayout: true,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Home",
              notification: false,
              children: [],
            },
            {
              path: "assetTypes",
              element: AsyncRoute(AssetTypes),
              label: translations.pages.assetManagement.tabs.assetTypes.label,
              menuItem: true,
              mainLayout: true,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Home",
              notification: false,
              children: [
                {
                  path: "assets/:id",
                  element: AsyncRoute(AssetDetails),
                  menuItem: false,
                  label: translations.pages.assetManagement.tabs.assets.label,
                  notification: false,
                  mainLayout: true,
                  scope: "Home" as ScopeEntry | undefined,
                },
                // {
                //   path: ":assetModelId",
                //   element: AsyncRoute(AssetModelMutations),
                //   menuItem: false,
                //   label: translations.pages.assetManagement.tabs.assetTypes.label,
                //   notification: false,
                //   mainLayout: true,
                //   scope: "AssetModel.Create" as ScopeEntry | undefined,
                // },
                {
                  addBreadcrumb: false,
                  path: "createAssetModel",
                  element: AsyncRoute(AssetModelCreate),
                  menuItem: false,
                  // exact: true,
                  label: "AssetAttributes",
                  notification: false,
                  mainLayout: true,
                  scope: "Home",
                },
                {
                  addBreadcrumb: false,
                  path: "updateAssetModel/:assetModelId",
                  element: AsyncRoute(AssetModelUpdate),
                  menuItem: false,
                  // exact: true,
                  label: "AssetAttributes",
                  notification: false,
                  mainLayout: true,
                  scope: "Home",
                },
                {
                  addBreadcrumb: false,
                  path: "newAssetType",
                  element: AsyncRoute(AssetTypeCreate),
                  menuItem: false,
                  // exact: true,
                  label: translations.pages.assetManagement.tabs.assetTypes.newAssetType,
                  notification: false,
                  mainLayout: true,
                  scope: "AssetType.Create" as ScopeEntry | undefined,
                },
                {
                  addBreadcrumb: false,
                  path: "updateAssetType/:assetTypeId",
                  element: AsyncRoute(AssetTypeUpdate),
                  menuItem: false,
                  // exact: true,
                  label: translations.pages.assetManagement.tabs.assetTypes.newAssetType,
                  notification: false,
                  mainLayout: true,
                  scope: "AssetType.Update" as ScopeEntry | undefined,
                },
              ],
            },
            {
              path: "assetAttributeFieldsAndSets",
              element: AsyncRoute(AssetAttributesSets),
              label: translations.pages.assetManagement.tabs.assetAttributeFieldsAndSets.label,
              menuItem: true,
              mainLayout: true,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Home" as ScopeEntry | undefined,
              notification: false,
              children: [],
            },
            {
              path: "assetNewEntry",
              element: AsyncRoute(NewEntry),
              label: translations.pages.assetManagement.tabs.assetNewEntry.label,
              menuItem: true,
              mainLayout: true,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Home" as ScopeEntry | undefined,
              notification: false,
              children: [
                {
                  path: ":tenantId",
                  element: AsyncRoute(NewEntryDetails),
                  label: translations.pages.assetManagement.tabs.assetNewEntry.label,
                  menuItem: false,
                  mainLayout: true,
                  scope: "Home" as ScopeEntry | undefined,
                  notification: false,
                },
              ],
            },
            {
              path: "safetyNotes",
              element: AsyncRoute(SafetyNotes),
              label: translations.pages.assetManagement.tabs.safetyNotes.label,
              menuItem: false,
              mainLayout: false,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "SafetyNote.Create.All" as ScopeEntry | undefined,
              notification: false,
              children: [
                {
                  addBreadcrumb: false,
                  path: "new",
                  element: AsyncRoute(SafetyNoteCreate),
                  menuItem: false,
                  // exact: true,
                  label: translations.pages.assetManagement.tabs.safetyNotes.newSafetyNote,
                  notification: false,
                  mainLayout: true,
                  scope: "SafetyNote.Create.All" as ScopeEntry | undefined,
                },
                {
                  addBreadcrumb: false,
                  path: "updateSafetyNote/:safetyNoteId",
                  element: AsyncRoute(SafetyNoteUpdate),
                  menuItem: false,
                  // exact: true,
                  label: translations.pages.assetManagement.tabs.safetyNotes.updateSafetyNote,
                  notification: false,
                  mainLayout: true,
                  scope: "SafetyNote.Create.All" as ScopeEntry | undefined,
                },
              ],
            },
            {
              path: "pendingAssets",
              element: AsyncRoute(PendingAssets),
              label: translations.pages.assetManagement.tabs.assets.pendingAssets,
              menuItem: false,
              mainLayout: false,
              menuIcon: ({ isActive }: { isActive: boolean }) => <AssetManagementIcon isActive={isActive} />,
              scope: "Asset.Update.All" as ScopeEntry | undefined,
              notification: false,
              children: [],
            },
          ],
        },
        {
          path: "/AssetPositioning",
          element: AsyncRoute(AssetPositioning),
          label: translations.pages.map.AssetPositioning,
          menuItem: true,
          mainLayout: true,
          menuIcon: ({ isActive }: { isActive: boolean }) =>
            tooltip({ icon: MapIcon, isActive, title: translations.pages.map.AssetPositioning }),
          scope: "Home",
          notification: false,
          children: [],
        },
        // user auth and settings needs to be removed
      ].concat(),
    },
  ];
  if (process.env.NODE_ENV === "development" && isAuthenticated) {
    routesArray[0]?.children?.push({
      path: "configurator",
      element: <Configurator />,
      menuIcon: ({ isActive }) => (
        <Tooltip title="Configurator">
          <div>
            <SettingsSuggestIcon sx={{ color: isActive ? projectColors.statusAndTag.error : "inherit" }} />
          </div>
        </Tooltip>
      ),
      label: "Configurator",
      menuItem: true,
      mainLayout: false,
      scope: "Home",
      notification: false,
    });
  }
  if (isAuthenticated) {
    routesArray[0]?.children?.push(
      {
        path: "/userManagement",
        element: AsyncRoute(UserManagement),
        menuItem: true,
        label: translations.pages.userManagement.label,
        mainLayout: true,
        menuIcon: ({ isActive }) => tooltip({ icon: UserAuthIcon, isActive, title: translations.pages.authUser.label }),
        scope: "Role.Read",
        notification: false,
        children: [
          {
            path: "pendingUserRequests",
            element: AsyncRoute(PendingUserRequests),
            menuItem: true,
            label: translations.pages.userManagement.userAuthorization,
            mainLayout: true,
            scope: "ApproveOrBlockUsers.Create",
            notification: false,
          },
        ],
      },
      {
        path: "userManagement/userRolesManager/updateRole/:roleId",
        element: AsyncRoute(UpdateRole),
        label: "",
        mainLayout: true,
        menuItem: false,
        scope: "Role.Update",
        notification: false,
        children: [],
      },
      {
        path: "userManagement/userRolesManager",
        element: AsyncRoute(UserRolesManager),
        label: "",
        mainLayout: true,
        menuItem: false,
        // scope: "Home",
        notification: false,
        children: [
          {
            path: "updateRole/:roleId",
            element: AsyncRoute(UpdateRole),
            label: translations.pages.userManagement.form.label.role,
            mainLayout: true,
            menuItem: false,
            scope: "Role.Update",

            notification: false,
          },
        ],
      },
      {
        path: "assignRolesToUser/:userId",
        element: AsyncRoute(MappingRolesUserUpdate),
        scope: "Hidden",
        label: translations.pages.userManagement.assignRolesToUser.label,
        mainLayout: true,
        menuItem: false,

        notification: false,
      },
      {
        path: "/myProfile",
        element: AsyncRoute(UserProfile),
        label: translations.entities.user.columns.myProfile,
        menuItem: false,
        mainLayout: false,
        scope: "Home",
        notification: false,
        children: [],
      },
      {
        path: "/settings",
        element: AsyncRoute(Settings),
        label: translations.pages.settings.label,
        menuItem: billingAdressScope ? true : false,
        mainLayout: true,
        menuIcon: ({ isActive }) => tooltip({ icon: SettingsIcon, isActive, title: translations.pages.settings.label }),
        scope:
          ("BillingAddress.Create.Tenant" as ScopeEntry | undefined) ||
          ("BillingAddress.Create.All" as ScopeEntry | undefined),
        notification: false,
        children: [
          {
            path: "contact",
            element: AsyncRoute(Contact),
            label: translations.pages.settings.label,
            menuItem: true,
            mainLayout: true,
            scope:
              ("BillingAddress.Create.Tenant" as ScopeEntry | undefined) ||
              ("BillingAddress.Create.All" as ScopeEntry | undefined),
            notification: false,
          },
        ],
      }
    );
  }
  return routesArray;
};
export default routes;
